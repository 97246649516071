<template>
  <div class="f-nosotros mb-5" style="margin-top:-5rem !important;">
      <div class="bg-nosotros img-fluid">
        <div class="container-fluid margin-text-titulo-nosotros">
          <div class="row">
            <div class="col-md-6">
              <img class="img-header-nosotros-movil" style="margin-top:7rem !important;" src="../assets/Nosotros/icono_principal.png" alt="">
              <h1 class="text-titulo-header-nosotros animate__animated  animate__bounceInDown animate__fast "><b>NOSOTROS</b></h1>
              <div class="tamaño-div-nosotros"><p class="text-header-nosotros ">Somos una empresa dedicada al crecimiento en las ventas digitales de las empresas mediante el uso de estrategias de marketing digital, embudos de ventas, análisis de datos, diseño web y manejo de redes sociales. </p></div>
            </div>
            <div class="col-md-6 mb-5">
              <img class="img-header-nosotros img-fluid" src="../assets/Nosotros/icono_principal.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 offset-md-1 text-align-l-nosotros">
              <img class="img-nosotros-grafica-movil img-fluid" src="../assets/Nosotros/grafica.png" alt="">
              <h1 class="text-titulo-nosotros"><b> ¿Por qué TECHSOFT? </b></h1>
              <p class="p-nosotros mt-4">Lo que nos diferencia de la competencia es que nosotros trabajamos por resultados; nuestro principal objetivo es crecer las ventas digitales de las empresas que nos contratan en menor tiempo posible además de que complementamos nuestras estrategias de marketing con desarrollo de software, automatizaciones y análisis de datos para crecer el ecommercce de nuestros clientes.</p>
              <b-button @click="$router.push('/f-creweb')" class="button-nosotros animate__animated animate__zoomIn mt-3"  variant="primary"><span class="text-button-nosotros">Conoce nuestros productos</span> </b-button>
          </div>
          <div class="col-md-5">
            <div class=""><img class="img-nosotros-grafica" src="../assets/Nosotros/grafica.png" alt=""></div>
          </div>
        </div>
      </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 offset-md-1">
          <img class="img-nosotros-garantia" src="../assets/Nosotros/garantia_techsoft.png" alt="">
        </div>
        <div class="col-md-6 offset-md-1">
          <h1 class="text-titulo-nosotros-2"><b> Nuestra garantía</b></h1>
          <p class="p-nosotros-2">Crecemos tus ventas online de forma rápida y sencilla. Te garantizamos que si no crecemos tus ventas online un 50% el primer mes, no nos pagas nada.</p>
          <p class="text-p-min-nosotros mt-3"><b> Consulta con nuestros ascesores los términos y condiciones para aplicar la garantía.</b></p>
          <div class="align-button-nosotros">
             <a href="https://wa.link/mkxh9x"> <b-button  class="button-nosotros2 animate__animated animate__zoomIn mt-3" variant="primary"><span class="text-button-nosotros2">Habla con un asesor</span> </b-button></a>
          </div>
          <div class="mb-5"></div>
        </div>
      </div>

    </div>
  </div> 
</template>

<script>
export default {

}
</script>

<style>
.f-nosotros{
  margin-bottom: 20%;
  margin-top: -1%;
}

.bg-nosotros { 
  /* The image used */
  background-image: url("../assets/Inicio/principal.png");
  margin-top: 5%;
  height: 130%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-header-nosotros-movil{
    display: none;
  }
  .img-nosotros-grafica-movil{
    display: none;
  }


@-webkit-keyframes mover2 {
    1% { transform: translateX(1px); }
    50% { transform: translateY(-15px); }
}

@media only screen and (max-width: 780px) {
    
  .img-header-nosotros{
    display: none;
  }
  .img-header-nosotros-movil{
    display: inline;
    width: 40%;
    margin-top: 15%;
    margin-bottom: 10%;
  }
  .text-titulo-header-nosotros{
    color: rgb(255, 255, 255);
    font-size: 47px !important;
    width: 80%;
    margin-left: 10%;
  }
  .tamaño-div-nosotros{
    margin-left: 0;
    width: 100%;
    margin-top:0 ;
  }
  .text-header-nosotros{
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: justify;
    padding: 5%;
    margin-top: 0px;
    
  }
  .text-titulo-nosotros{
    font-size: 1.6rem !important;
    text-align: center;
  }
  
  .text-align-l-nosotros{
  text-align: justify;
  }
  .p-nosotros{
    font-size: 1.2rem !important;
    width: 100%;
  }
  .button-nosotros{
    width: 75%;
    height: 6%;
    margin-bottom: 15%;
    margin-left: 10%;
    
    
  }
  .text-button-nosotros{
    font-size:15px;
    margin-top: 5%;
    
  }
  .img-nosotros-grafica{
    display: none;
  }
  .img-nosotros-grafica-movil{
    display: inline;
    width: 70%;
    height: 30%;
    margin-top: 12%;
    margin-left: 10%;
  }
  .text-titulo-nosotros-2{
    font-size: 1.6rem !important;
    margin-top: 10%;
    text-align: center;
  }
  .p-nosotros-2{
    font-size: 1.2rem !important;
    text-align: justify;
  }
  .text-p-min-nosotros{
    text-align: center;
    font-size: 0.8rem !important;
  }
  .align-button-nosotros{
    text-align: center;
  }
  .button-nosotros2{
    width: 75%;
    height: 10%;
    margin-bottom: 15%;
    margin-left: 10%;
    margin-left: 0px;
  }
  .text-button-nosotros2{
    font-size:15px;
    
    
}
}
@media only screen and (max-width: 1070px) and (min-width: 720px) {
  .text-titulo-header-nosotros, .img-header-nosotros{
    margin-top: 3rem !important;
    
  }
  
}

</style>